import axios, { AxiosResponse } from 'axios';
import moment from 'moment';

const apiClient = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  responseType: 'json',
});

let errorCount: number = 0;

apiClient.interceptors.request.use(config => {
  const token = localStorage.getItem('token');
  const loginAs = localStorage.getItem('loginAs');

  if (token) {
    config.headers.common.Authorization = `Bearer ${token}`;
    config.headers.ts = moment();
  }

  if (loginAs) {
    const { id } = JSON.parse(loginAs);

    config.headers.common.loginAs = id;
  }

  return config;
});

apiClient.interceptors.response.use(
  (response: AxiosResponse) => {
    if (errorCount !== 0) {
      errorCount = 0;
    }

    setTimeout(() => {
      const monitor: string | null = localStorage.getItem('monitor');
      const data: any[] = monitor ? JSON.parse(monitor) : [];

      const startTime = response?.config?.headers['ts'];
      const _baseUrl = response.config.baseURL?.slice(0, -1);

      if (startTime) {
        const saveRes = {
          id: Math.floor(100000 + Math.random() * 900000),
          status: response.status,
          url: !response.config.url?.startsWith('https') ? `${_baseUrl}${response.config.url}` : response.config.url,
          method: response.config.method,
          contentLength: response.headers['content-length'],
          startTime,
          duration: moment.duration(startTime.diff(moment())).asMilliseconds(),
        };

        data.push(saveRes);
        localStorage.setItem('monitor', JSON.stringify(data));
      }
    });

    return response;
  },
  error => {
    setTimeout(() => {
      const monitor: string | null = localStorage.getItem('monitor');
      const data: any[] = monitor ? JSON.parse(monitor) : [];

      const startTime = error?.response?.config?.headers['ts'];

      if (startTime) {
        const saveRes = {
          id: Math.floor(100000 + Math.random() * 900000),
          status: error.response.status,
          url: !error.config.url?.startsWith('https') ? `${error.config.baseURL}${error.config.url}` : error.config.url,
          method: error.response.config.method,
          startTime,
          duration: moment.duration(startTime.diff(moment())).asMilliseconds(),
        };

        data.push(saveRes);
        localStorage.setItem('monitor', JSON.stringify(data));
      }
    });

    if (errorCount && error.response) {
      console.log({
        message: `${error?.response?.status}`,
        description: `${error?.response?.statusText}`,
        style: { borderColor: 'red' },
        duration: 3,
      });
    }

    return Promise.reject(error);
  },
);

apiClient.interceptors.response.use(
  response => response,
  error => {
    const { response } = error;

    if (response) {
      const { data } = response;

      return Promise.reject(data);
    }

    return Promise.reject(error);
  },
);

export default apiClient;
