/**
 * App.tsx
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 *
 */
import React, { memo, useEffect } from 'react';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';

import { AxiosResponse } from 'axios';
import { error } from 'components/_shared/snackbars/';
import { useAuth } from 'components/auth';
import { getUser } from 'components/auth/api';
import EnsureTranslation from 'components/language/components/EnsureTranslation';
import 'font-awesome/css/font-awesome.min.css';
import { useIntl } from 'react-intl';
import { useQuery } from 'react-query';
import { useIsLatestVersion } from '../components/_shared/hooks/useIsLatestVersion';
import { Loader } from '../components/_shared/loader';
import AuthCallback from './AuthCallback';
import Dashboard from './Dashboard';
import Guest from './Guest';
function App() {
  const isLatestVersion = useIsLatestVersion();
  const intl = useIntl();
  const { user, token, setUser, setToken, isAuth } = useAuth();

  const { refetch } = useQuery('currentUser', getUser, {
    enabled: false,
    onSuccess: (response: AxiosResponse<any>) => {
      setUser(response.data.user);
    },
    onError: ({ statusCode }) => {
      setToken();

      if (statusCode !== 401) {
        error(EnsureTranslation({ intl, itemKey: 'general.errorLoginAgain' }));
      }
    },
  });

  useEffect(() => {
    if (!!token && !user) {
      refetch();
    }
  }, [token, user, refetch]);

  if (!isLatestVersion) {
    return (
      <>
        <Loader loading={true} fullPage={true} />
      </>
    );
  }

  return (
    <Router>
      <Switch>
        <Route path="/auth/:provider/callback" exact component={AuthCallback} />
        <Route path="/" component={isAuth ? Dashboard : Guest} />
      </Switch>
    </Router>
  );
}

export default memo(App);
