import { CURRENT_LANGUAGE } from 'configs/formatTranslationMessages';

export interface Props {
  itemKey: string;
  value?: any;
  intl: any;
}

export default function EnsureTranslation({ intl, itemKey, value }: Props) {
  const { messages, formatMessage } = intl;

  const arrayItems: string[] = itemKey.split('.');
  const key = getValueAt(messages, arrayItems);

  // @ts-ignore
  if (typeof key !== 'undefined') {
    if (typeof value !== 'undefined') {
      // @ts-ignore
      return formatMessage({ id: `${itemKey}`, defaultMessage: key }, { value: value });
    }
    // @ts-ignore
    return key;
  }

  return `Missing key for: ${CURRENT_LANGUAGE}.${itemKey}`;
}

function getValueAt(obj: {}, keyPathArray: string[]) {
  let emptyObj = {};

  return keyPathArray.reduce(function(o, key) {
    // @ts-ignore
    return (o || emptyObj)[key];
  }, obj);
}
