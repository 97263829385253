import { AxiosPromise } from 'axios';

import apiClient from 'configs/axios';

/**
 * Send request to get the user
 * @returns {AxiosPromise<any>}
 */
export function getUser(): AxiosPromise {
  return apiClient.get('/auth/user');
}

/**
 * Send request to get a login as user
 * @returns {AxiosPromise<any>}
 */
export function loginAsRequest({ id }: { id: number }): AxiosPromise {
  return apiClient.get(`/auth/login-as/${id}`);
}

/**
 * Send request to get a token
 * @returns {AxiosPromise<any>}
 */
export function googleLoginRequest(data: { code: string }): AxiosPromise {
  return apiClient.post('/auth/google-login', data);
}

/**
 * Send request to get a token
 * @returns {AxiosPromise<any>}
 */
export function questionProRequest(code: string): AxiosPromise {
  return apiClient.get(`/auth/question-pro-login/${code}`);
}
