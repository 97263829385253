import makeStyles from '@mui/styles/makeStyles';
import cx from 'clsx';
import React, { useState } from 'react';
import '../styles/loader.scss';

import styles from '../styles';

interface Props {
  loading: boolean;
  className?: string;
  children?: React.ReactNode;
  fullPage?: boolean;
  background?: boolean;
  notUnmount?: boolean;
  withMinTime?: boolean;
}

const useStyles = makeStyles(styles);

function Loader({
  className = '',
  fullPage = true,
  loading,
  notUnmount = false,
  children = '',
  background = true,
  withMinTime = false,
}: Props) {
  const classes = useStyles();

  const [state, setState] = useState<boolean>(loading);

  if (state !== loading) {
    if (loading) {
      setState(true);
    } else {
      setTimeout(() => setState(false), withMinTime ? 1000 : 1);
    }
  }

  return (
    <>
      {state && (
        <>
          <div
            className={cx(classes.loaderWrapper, className, {
              [classes.loaderFullPageWrapper]: fullPage,
              [classes.loaderWrapperBackground]: background,
            })}
            data-testid="loader"
          >
            <div className="loader-animation">
              <span />
              <span />
              <span />
              <span />
            </div>
          </div>
        </>
      )}
      {(notUnmount || !loading) && children}
    </>
  );
}

export default Loader;
