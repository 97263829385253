/**
 * i18n.js
 *
 * This will setup the i18n language files and locale data for your app.
 *
 */

const enTranslationMessages = require('../translations/en.json');
const deTranslationMessages = require('../translations/de.json');

const language: string = localStorage.getItem('language')
  ? JSON.parse(JSON.stringify(localStorage.getItem('language')))
  : 'en';
export const CURRENT_LANGUAGE = language;

// @ts-ignore
export const formatTranslationMessages = (locale, messages) => {
  // @ts-ignore
  const defaultFormattedMessages =
    locale !== CURRENT_LANGUAGE ? formatTranslationMessages(CURRENT_LANGUAGE, enTranslationMessages) : {};
  // @ts-ignore
  const flattenFormattedMessages = (formattedMessages, key) => {
    // @ts-ignore
    const formattedMessage =
      !messages[key] && locale !== CURRENT_LANGUAGE ? defaultFormattedMessages[key] : messages[key];

    return { ...formattedMessages, [key]: formattedMessage };
  };

  return Object.keys(messages).reduce(flattenFormattedMessages, {});
};

export const translationMessages = {
  de: formatTranslationMessages('de', deTranslationMessages),
  en: formatTranslationMessages('en', enTranslationMessages),
};
