import React from 'react';
import { Redirect } from 'react-router-dom';

import { useAuth } from '../hooks/useAuth';

function LogoutAs() {
  const { setLoginAs, loginAs } = useAuth();

  const url = loginAs ? `/users/${loginAs.id}` : '/';
  setLoginAs();

  return (
    <>
      <Redirect to={url} />
    </>
  );
}

export default LogoutAs;
