import { Theme } from '@mui/material';

// Variables - Styles
const fontFamily = 'Fira Sans,sans-serif';

const boxShadow = {
  boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.25)',
};
const boxShadowHover = {
  boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.15)',
};

const transition = {
  transition: 'all 0.2s cubic-bezier(0.685, 0.0473, 0.346, 1)',
};

const layoutHorizontalIndent = (theme: Theme) => ({
  paddingLeft: 16,
  paddingRight: 16,
  [theme.breakpoints.up('sm')]: {
    paddingLeft: 20,
    paddingRight: 20,
  },
  [theme.breakpoints.up('md')]: {
    paddingLeft: 32,
    paddingRight: 32,
  },
});

// Sizes
const drawerWidth = 240;
const textFieldHeight = 36;
const textFieldFontSize = 14;
const sliderHeight = 4;
const borderRadiusSmallSize = 4;
const borderRadiusSize = 6;

// System Color
const whiteColor = '#FFF';
const primaryColor = '#1b87e6';
const primaryColorDark = '#1b3380';
const primaryColorDisable = '#AFBEE3';
const secondaryColor = '#b9caf8';
const secondaryColorDark = '#DCE4FB';
const secondaryColorDisable = '#EFF1F8';
const successColor = '#19BF6F';
const successColorDark = '#19AB4F';
const successSecondaryColor = '#E8F9F1';
const successSecondaryColorDark = '#CAF5E1';
const dangerColor = '#e15422';
const dangerColorDark = '#D55E4C';
const dangerSecondaryColor = '#FDEFED';
const dangerSecondaryColorDark = '#FFDFDC';
const warningColor = '#E68819';
const waitingColor = '#DFBF13';
const disableColor = '#D1D1DC';
const greyColor = '#8F91A0';
const lightGreyColor = '#F6F5F6';
const lighterGreyColor = '#F9F9F9';
const infoColor = '#BCE3FF';

// Status Color
const activeColor = '#E8F9F1';
const activeTextColor = '#19BF6F';
const inactiveColor = '#FDEFED';
const inactiveTextColor = '#E9604E';
const newColor = '#FCF9E8';
const newTextColor = '#DFBF13';
const searchColor = '#FCF3E8';
const searchTextColor = '#E68819';
const interviewColor = '#F1EDFD';
const interviewTextColor = '#764EE9';
const closedColor = '#EDF2FD';
const closedTextColor = '#4E79E9';
const draftColor = '#F4F4F5';
const draftTextColor = '#8F91A0';
const headerColor = '#444';

// Layout Background Color
const bgBody = '#b3e5fc';
const bgGrey = '#FFF';

// Avatar Colors
const avatarColors = ['#85A7FF', '#FFBB6B', '#AA8CFF', '#F7DE59', '#83BCFF', '#51DD9A'];

// Button
const btnPrimaryColorOutlineHover = '#E9EFFF';
const btnSecondaryColorOutlineHover = '#F5F7FF';

// Text Color
const textPrimaryColor = '#545E6B';
const textSecondaryColor = '#9B9B9B';
const labelColor = '#696B80';
const textGreyColor = '#696B80';
const textLightGreyColor = '#C6C7D0';

// Border Color
const borderPrimaryColor = '#DDDDE0';
const borderSecondaryColor = '#E7EBEE';

export {
  // Status Color
  activeColor,
  activeTextColor,
  // Avatar Colors
  avatarColors,
  // Layout Background Color
  bgBody,
  bgGrey,
  // Border Color
  borderPrimaryColor,
  borderRadiusSize,
  borderRadiusSmallSize,
  borderSecondaryColor,
  boxShadow,
  boxShadowHover,
  // Button
  btnPrimaryColorOutlineHover,
  btnSecondaryColorOutlineHover,
  closedColor,
  closedTextColor,
  dangerColor,
  dangerColorDark,
  dangerSecondaryColor,
  dangerSecondaryColorDark,
  disableColor,
  draftColor,
  draftTextColor,
  // Sizes
  drawerWidth,
  fontFamily,
  greyColor,
  headerColor,
  inactiveColor,
  inactiveTextColor,
  infoColor,
  interviewColor,
  interviewTextColor,
  labelColor,
  layoutHorizontalIndent,
  lightGreyColor,
  lighterGreyColor,
  newColor,
  newTextColor,
  primaryColor,
  primaryColorDark,
  primaryColorDisable,
  searchColor,
  searchTextColor,
  secondaryColor,
  secondaryColorDark,
  secondaryColorDisable,
  sliderHeight,
  successColor,
  successColorDark,
  successSecondaryColor,
  successSecondaryColorDark,
  textFieldFontSize,
  textFieldHeight,
  textGreyColor,
  textLightGreyColor,
  // Text Color
  textPrimaryColor,
  textSecondaryColor,
  transition,
  waitingColor,
  warningColor,
  // System Color
  whiteColor,
};
