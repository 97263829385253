import React from 'react';

import { DispatchContext, StateContext } from '../context';
import { TYPE } from '../constants';
import { UserType } from 'components/users/types';

export interface AuthType {
  readonly user?: UserType;
  readonly token?: string;
  readonly loginAs?: UserType;
  readonly FCMToken?: { id: string | number; token: string };
  readonly isAuth: boolean;
  readonly setToken: (token?: string) => void;
  readonly setFCMToken: (token?: string) => void;
  readonly setLoginAs: (user?: UserType) => void;
  readonly setUser: (data: any) => void;
}

type Dispatch = (type: TYPE, payload?: any) => void;

export function useAuth(): AuthType {
  const { token, user, FCMToken, loginAs } = React.useContext(StateContext);
  const dispatch = useDispatch();
  const isAuth = !!token;

  const setUser = (data: any) => dispatch(TYPE.SET_USER, data);
  const setToken = (token: string) => setTokenAction(dispatch, token);
  const setFCMToken = (token: string) => setFCMTokenAction(dispatch, token);
  const setLoginAs = (data?: UserType) => setLoginAsAction(dispatch, data);

  return { user, isAuth, token, setUser, setToken, setFCMToken, FCMToken, setLoginAs, loginAs };
}

export function useDispatch(): Dispatch {
  const dispatch = React.useContext(DispatchContext);

  if (dispatch === undefined) {
    throw new Error('Something went wrong');
  }

  return (type: TYPE, payload?: any) => {
    dispatch({ type, payload });
  };
}

function setTokenAction(dispatch: Dispatch, token: string = '') {
  localStorage.setItem('token', token);
  localStorage.setItem('isLogin', 'true');
  dispatch(TYPE.SET_TOKEN, token);
}

function setFCMTokenAction(dispatch: Dispatch, token: string = '') {
  localStorage.setItem('FCMToken', JSON.stringify(token));

  dispatch(TYPE.SET_FCM_TOKEN, token);
}

function setLoginAsAction(dispatch: Dispatch, user?: UserType) {
  if (user) {
    localStorage.setItem('loginAs', JSON.stringify(user));

    dispatch(TYPE.SET_LOGIN_AS, user);
  } else {
    localStorage.removeItem('loginAs');

    dispatch(TYPE.SET_LOGIN_AS, undefined);
  }
}
