import React, { Children, useContext, useEffect, useState } from 'react';
import { IntlProvider } from 'react-intl';
import { LanguageContext } from '../context/LanguageContext';

export interface Props {
  readonly messages: { [locale: string]: { [id: string]: string } };
  readonly children?: React.ReactNode;
}

export default function LanguageProvider({ messages, children }: Props) {
  const languageContext = useContext(LanguageContext);
  const [language, setLanguage] = useState(languageContext.items);

  useEffect(() => {
    setLanguage(languageContext.items);
  }, [languageContext]);

  return (
    <IntlProvider locale={language} key={language} messages={messages[language]}>
      {Children.only(children)}
    </IntlProvider>
  );
}
