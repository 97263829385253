import { useQuery, QueryOptions, QueryObserverResult } from 'react-query';
import { AxiosPromise, AxiosResponse } from 'axios';

import { AxiosError } from 'types';

export default function useAsyncQuery<T>(
  key: string,
  fetcher: (...args: any) => AxiosPromise<T>,
  config: QueryOptions<AxiosResponse<T>, AxiosError> = {},
): QueryObserverResult<AxiosResponse<T>, AxiosError> {
  return useQuery(key, fetcher, { refetchOnWindowFocus: false, retry: false, ...config });
}
