import React, { memo } from 'react';

import Alert, { Props as AlertProps } from 'components/_shared/alert/components/Alert';

const Message = React.forwardRef((props: AlertProps, ref: React.RefObject<HTMLElement>) => {
  return (
    <span ref={ref}>
      <Alert {...props} />
    </span>
  );
});

export default memo(Message);
