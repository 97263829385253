import { AxiosPromise } from 'axios';

import apiClient from 'configs/axios';
import { AnyObject } from '../../../types';

/**
 * Send request to get an users list
 * @returns {AxiosPromise<any>}
 */
export function loadUsersRequest(params = {}): AxiosPromise {
  return apiClient.get('/users', { params });
}

/**
 * Send request to get an users org list
 * @returns {AxiosPromise<any>}
 */
export function loadUsersOrganizationRequest(params = {}): AxiosPromise {
  return apiClient.get('auth/all-users', { params });
}
export function postMessageContactSales(data: any): AxiosPromise {
  return apiClient.post('/contact-sales', data);
}
/**
 * Send request to get an users list
 * @returns {AxiosPromise<any>}
 */
export function loadUserRequest(id: number | string, params = {}): AxiosPromise {
  return apiClient.get(`/users/${id}`, { params });
}

/**
 * Send request to change an user
 * @returns {AxiosPromise<any>}
 */
export function updateUserRequest({ id, data }: { id: number | string; data: AnyObject }): AxiosPromise {
  return apiClient.patch(`/users/${id}`, data);
}

/**
 * Send request to put user as admin
 * @returns {AxiosPromise<any>}
 */
export function putUserSetAdminById(id: number | string, params: AnyObject): AxiosPromise {
  return apiClient.put(`/users/set-admin/${id}`, params);
}

/**
 * Send request to get usage statistic
 * @returns {AxiosPromise<any>}
 */
export function usageStatisticRequest(params: AnyObject): AxiosPromise {
  return apiClient.get('/statistic/user-usage', { params });
}

export function getLimitedReach(): AxiosPromise {
  return apiClient.get(`/locations/limit-reached`);
}
