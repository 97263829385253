import { useState, useEffect } from 'react';
import { useQuery, QueryOptions, QueryObserverResult } from 'react-query';
import { AxiosPromise, AxiosResponse } from 'axios';

import { AxiosError } from 'types';

interface Response<T> {
  data: T[];
  page: number;
  count: number;
  total: number;
  perPage: number;
  pageCount: number;
}

type Result<T> = QueryObserverResult<AxiosResponse<Response<T>>, AxiosError> & { setFilter: (params: any) => void };

export default function useAsyncQuery<T>(
  key: string,
  fetcher: (...args: any) => AxiosPromise<Response<T>>,
  config: QueryOptions<AxiosResponse<Response<T>>, AxiosError> = {},
  defaultFilter: object = {},
): Result<T> {
  const [filter, setFilter] = useState(defaultFilter);

  const queryData = useQuery(key, () => fetcher(filter), {
    enabled: false,
    refetchOnWindowFocus: false,
    retry: false,
    ...config,
  });

  const { refetch } = queryData;

  useEffect(() => {
    refetch();
  }, [filter, refetch]);

  return { ...queryData, setFilter };
}
