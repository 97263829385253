import { CURRENT_LANGUAGE } from 'configs/formatTranslationMessages';
import React, { Dispatch, FC, SetStateAction } from 'react';

interface Props {
  children: React.ReactNode;
}

interface LanguageContextType {
  items: string;
  setItems: Dispatch<SetStateAction<string>>;
}

const language = localStorage.getItem('language')
  ? JSON.parse(JSON.stringify(localStorage.getItem('language')))
  : CURRENT_LANGUAGE;

const contextDefaultValues: LanguageContextType = {
  items: language,
  setItems: () => '',
};

const LanguageContext = React.createContext<LanguageContextType>(contextDefaultValues);

const LanguageContextProvider: FC<Props> = ({ children }) => {
  const [items, setItems] = React.useState(contextDefaultValues.items);

  return <LanguageContext.Provider value={{ items, setItems }}>{children}</LanguageContext.Provider>;
};

export { LanguageContext, LanguageContextProvider };
