import { createTheme } from '@mui/material/styles';

import {
  bgGrey,
  borderPrimaryColor,
  borderRadiusSize,
  borderSecondaryColor,
  boxShadow,
  btnPrimaryColorOutlineHover,
  btnSecondaryColorOutlineHover,
  dangerColor,
  dangerColorDark,
  dangerSecondaryColor,
  dangerSecondaryColorDark,
  fontFamily,
  greyColor,
  headerColor,
  labelColor,
  lightGreyColor,
  lighterGreyColor,
  primaryColor,
  primaryColorDark,
  primaryColorDisable,
  secondaryColor,
  secondaryColorDark,
  secondaryColorDisable,
  sliderHeight,
  successColor,
  successColorDark,
  successSecondaryColor,
  successSecondaryColorDark,
  textFieldFontSize,
  textFieldHeight,
  textGreyColor,
  textPrimaryColor,
  whiteColor,
} from 'configs/variables';

let exampleTheme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 300,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
});

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 300,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  palette: {
    primary: {
      main: primaryColor,
      dark: primaryColorDark,
      contrastText: whiteColor,
    },
    secondary: {
      main: secondaryColor,
      dark: secondaryColorDark,
    },
    error: {
      main: dangerColor,
    },
    success: {
      main: successColor,
    },
    background: {
      default: bgGrey,
    },
    text: {
      primary: textPrimaryColor,
      secondary: textGreyColor,
    },
  },
  typography: {
    fontFamily: fontFamily,
    h1: {
      fontSize: '1.625rem', // 26
      lineHeight: 1.2,
      [exampleTheme.breakpoints.up('sm')]: {
        fontSize: '1.75rem', // 28
      },
      [exampleTheme.breakpoints.up('md')]: {
        fontSize: '2rem', // 32
      },
    },
    h2: {
      fontSize: '1.375rem', // 22
      [exampleTheme.breakpoints.up('sm')]: {
        fontSize: '1.625rem', // 26
      },
      [exampleTheme.breakpoints.up('md')]: {
        fontSize: '1.75rem', // 28
      },
    },
    h3: {
      fontSize: '1.25rem', // 20
      [exampleTheme.breakpoints.up('sm')]: {
        fontSize: '1.375rem', // 22
      },
      [exampleTheme.breakpoints.up('md')]: {
        fontSize: '1.5rem', // 24
      },
    },
    h4: {
      fontSize: '1.25rem', // 16
      [exampleTheme.breakpoints.down('sm')]: {
        fontSize: '1.5rem', // 18
      },
      '@media (min-height:720px)': {
        fontSize: '1rem',
      },
    },
    h5: {
      fontSize: '1rem', // 16
    },
    h6: {
      fontSize: '0.875rem', // 14
      fontWeight: 400,
    },
    body1: {
      lineHeight: 1,
    },
    body2: {
      fontSize: 14,
      fontWeight: 400,
    },
    caption: {
      fontSize: 12,
    },
    subtitle2: {
      color: greyColor,
      fontSize: '0.6em',
      fontWeight: 400,
      lineHeight: 1.3,
    },
    subtitle1: {
      fontSize: 14,
    },
    button: {
      fontSize: 14,
      fontWeight: 400,
      lineHeight: 1.4,
      textTransform: 'none',
    },
  },
  components: {
    MuiToggleButtonGroup: {
      styleOverrides: {
        root: {
          borderRadius: 0,
          backgroundColor: lighterGreyColor,
          overflow: 'hidden',
        },
      },
    },
    // @ts-ignore
    MuiToggleButton: {
      styleOverrides: {
        root: {
          fontSize: '0.8rem !important',
          border: 0,
          paddingRight: '16px !important',
          paddingLeft: '16px !important',
          borderBottomLeftRadius: '0 !important',
          borderTopLeftRadius: '0 !important',
          borderBottomRightRadius: '0 !important',
          borderTopRightRadius: '0 !important',
          '&.Mui-selected': {
            backgroundColor: primaryColor,
            color: whiteColor,
            '&:hover': {
              backgroundColor: primaryColor,
              color: whiteColor,
            },
          },
        },
        sizeSmall: {
          height: 40,
          padding: 10,
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        gutterBottom: {
          marginBottom: '0.45em',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: '0.875rem !important',
          borderRadius: 0,
          padding: '11px 16px 10px',
          transition: 'none !important',
          '&, &:hover': {
            boxShadow: 'none',
          },
          '&$disabled, &$disabled:hover': {
            color: primaryColorDisable,
          },
          '& svg': {
            fontSize: '1.15em',
          },
        },
        startIcon: {},
        contained: {
          '&, &:hover': {
            boxShadow: 'none !important',
          },
          '&.button-success': {
            color: whiteColor,
            backgroundColor: successColor,
            '&:hover, &$disabled, &$disabled:hover': {
              color: whiteColor,
              backgroundColor: successColorDark,
            },
            '&$disabled, &$disabled:hover': {
              opacity: 0.5,
            },
          },
          '&.button-danger': {
            color: whiteColor,
            backgroundColor: dangerColor,
            '&:hover, &$disabled, &$disabled:hover': {
              color: whiteColor,
              backgroundColor: dangerColorDark,
            },
            '&$disabled, &$disabled:hover': {
              opacity: 0.5,
            },
          },
        },
        containedPrimary: {
          color: whiteColor,
          '&$disabled, &$disabled:hover': {
            color: whiteColor,
            backgroundColor: primaryColorDisable,
          },
        },
        containedSecondary: {
          color: primaryColor,
          '&$disabled, &$disabled:hover': {
            color: primaryColorDisable,
            backgroundColor: secondaryColorDisable,
          },
          '&.button-danger': {
            color: dangerColor,
            backgroundColor: dangerSecondaryColor,
            '&:hover, &$disabled, &$disabled:hover': {
              color: dangerColor,
              backgroundColor: dangerSecondaryColorDark,
            },
            '&$disabled, &$disabled:hover': {
              opacity: 0.5,
            },
          },
          '&.button-success': {
            color: successColor,
            backgroundColor: successSecondaryColor,
            '&:hover, &$disabled, &$disabled:hover': {
              color: successColor,
              backgroundColor: successSecondaryColorDark,
            },
            '&$disabled, &$disabled:hover': {
              opacity: 0.5,
            },
          },
        },
        outlined: {
          padding: '10px 16px 9px',
        },
        outlinedPrimary: {
          borderColor: primaryColor,
          '&:hover': {
            backgroundColor: btnPrimaryColorOutlineHover,
          },
          '&$disabled, &$disabled:hover': {
            borderColor: primaryColorDisable,
          },
        },
        outlinedSecondary: {
          color: primaryColor,
          borderColor: secondaryColor,
          '&:hover': {
            backgroundColor: btnSecondaryColorOutlineHover,
          },
          '&$disabled, &$disabled:hover': {
            borderColor: secondaryColorDisable,
          },
        },
        text: {
          padding: '7px 8px 6px',
          '& $startIcon': {
            marginTop: -1,
          },
        },
        textPrimary: {
          '&:hover': {
            color: primaryColorDark,
          },
        },
        textSecondary: {
          '&:hover': {
            color: secondaryColorDark,
          },
        },
        containedSizeSmall: {
          padding: '7px 10px 6px',
        },
        iconSizeSmall: {
          marginRight: 4,
        },
      },
    },
    MuiFab: {
      styleOverrides: {
        root: {
          width: 36,
          height: 36,
          minHeight: 36,
          borderRadius: borderRadiusSize,
          fontWeight: 400,
          '&, &:hover': {
            boxShadow: 'none',
          },
          '& svg': {
            fontSize: 35,
          },
        },
        primary: {
          '&$disabled, &$disabled:hover': {
            color: whiteColor,
            backgroundColor: primaryColorDisable,
          },
          '&.button-success': {
            color: whiteColor,
            backgroundColor: successColor,
            '&:hover, &$disabled, &$disabled:hover': {
              color: whiteColor,
              backgroundColor: successColorDark,
            },
            '&$disabled, &$disabled:hover': {
              opacity: 0.5,
            },
          },
          '&.button-danger': {
            color: whiteColor,
            backgroundColor: dangerColor,
            '&:hover, &$disabled, &$disabled:hover': {
              color: whiteColor,
              backgroundColor: dangerColorDark,
            },
            '&$disabled, &$disabled:hover': {
              opacity: 0.5,
            },
          },
        },
        secondary: {
          color: primaryColor,
          '&$disabled, &$disabled:hover': {
            color: primaryColorDisable,
            backgroundColor: secondaryColorDisable,
          },
          '&.button-danger': {
            color: dangerColor,
            backgroundColor: dangerSecondaryColor,
            '&:hover, &$disabled, &$disabled:hover': {
              color: dangerColor,
              backgroundColor: dangerSecondaryColorDark,
            },
            '&$disabled, &$disabled:hover': {
              opacity: 0.5,
            },
          },
          '&.button-success': {
            color: successColor,
            backgroundColor: successSecondaryColor,
            '&:hover, &$disabled, &$disabled:hover': {
              color: successColor,
              backgroundColor: successSecondaryColorDark,
            },
            '&$disabled, &$disabled:hover': {
              opacity: 0.5,
            },
          },
        },
        sizeSmall: {
          width: 26,
          height: 26,
          minHeight: 26,
          fontSize: '0.875rem !important',
          '& svg': {
            fontSize: 20,
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          padding: 10,
          '&.button-success': {
            color: successColor,
            '&:hover': {
              color: successColorDark,
            },
          },
          '&.button-danger': {
            color: dangerColor,
            '&:hover': {
              color: dangerColorDark,
            },
          },
        },
        sizeSmall: {
          padding: 6,
          '& svg': {
            fontSize: 'inherit',
          },
        },
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          fontFamily: fontFamily,
        },
      },
    },
    MuiTouchRipple: {
      styleOverrides: {
        root: {
          transition: 'none !important',
        },
      },
    },
    MuiTextField: {},
    MuiInputLabel: {
      styleOverrides: {
        root: {
          zIndex: 1,
          fontWeight: 400,
          lineHeight: '16px',
          padding: 0,
          maxWidth: '100%',
          marginLeft: 10,
          fontSize: 16,

          '&, &$focused:not($error)': {
            color: labelColor,
          },
        },
        formControl: {
          transform: 'none !important',
        },
        shrink: {
          transform: 'translate(0, -15px) scale(0.75)',
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        formControl: {
          'label + &': {
            marginTop: 0,
          },
        },
        underline: {
          '&:before,&:after': {
            content: 'none',
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          fontSize: textFieldFontSize,
          padding: 0,
          backgroundColor: whiteColor,
          borderBottom: `1px solid ${borderPrimaryColor}`,
          '&$disabled': {
            backgroundColor: lightGreyColor,
          },
          // fix for multiple height grow field (chat)
          '& $input:not($inputMultiline)': {
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
          },
        },
        input: {
          color: textPrimaryColor,
          height: textFieldHeight,
          boxSizing: 'border-box',
          border: 'none',
          padding: '9px 8px 5px 8px',
        },
        multiline: {
          padding: 0,
        },
        inputMultiline: {
          padding: '9px 8px 5px 8px',
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          '&&': {
            paddingRight: 30,
          },
          '&, &:focus': {
            borderRadius: borderRadiusSize,
          },
          '&:focus': {
            backgroundColor: 'inherit',
          },
        },
      },
    },
    // @ts-ignore
    MuiAutocomplete: {
      styleOverrides: {
        inputRoot: {
          minHeight: 42,
          flexWrap: 'nowrap',
          paddingLeft: 8,
          paddingBottom: '0 !important',
          '&[class*="MuiInput-root"] $input:first-of-type': {
            '&, &:first-of-type': {
              padding: '10px 0',
            },
          },
          '&input.MuiInputBase-inputAdornedStart': {
            opacity: 0,
            position: 'absolute',
            width: '100%',
            left: 0,
          },
        },
        paper: {
          minWidth: '100%',
          margin: 0,
          borderRadius: 0,
        },
        listbox: {
          padding: 0,
          fontSize: 14,
          '& li': {
            padding: '0',
          },
          '& .MuiCheckbox-root': {
            padding: 0,
          },
          '& .MuiAutocomplete-option': {
            paddingLeft: 8,
            paddingRight: 8,
            paddingTop: 10,
            paddingBottom: 10,
          },
        },
        tag: {
          marginLeft: 0,
          marginBottom: 0,
          fontSize: 13,
          justifyContent: 'left',
          backgroundColor: 'transparent',

          '& .MuiChip-label': {
            padding: 0,
            PointerEvent: 'none',
          },
          '& .MuiSvgIcon-root': {
            display: 'none',
          },
        },
        input: {
          padding: 0,
        },
        clearIndicator: {
          padding: 4,
          '& svg': {
            fontSize: textFieldFontSize,
          },
        },
        popupIndicator: {
          '&:hover, &:focus': {
            backgroundColor: 'transparent',
          },
        },
        hasPopupIcon: {
          '&.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot': {
            paddingRight: 24,
          },
        },
        noOptions: {
          fontSize: textFieldFontSize,
          padding: '15px 8px',
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {},
        label: {
          fontSize: textFieldFontSize,
          '&$disabled': {
            '& a': {
              color: 'inherit',
            },
          },
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: borderPrimaryColor,
          padding: 4,
          '& svg': {
            fontSize: 16,
          },
          '&$disabled': {
            '& svg': {
              opacity: 0.5,
            },
          },
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          color: borderPrimaryColor,
          padding: 4,
          '& svg': {
            fontSize: 16,
          },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontSize: 10,
          marginTop: '0.4em',
          marginBottom: 'calc(-1em - 0.4em)',
          lineHeight: 1,
        },
      },
    },
    // MuiPickersCalendar: {
    //   styleOverrides: {
    //     transitionContainer: {
    //       overflow: 'hidden',
    //       [exampleTheme.breakpoints.down('xs')]: {
    //         minHeight: 190,
    //       },
    //     },
    //   }
    // },
    // MuiPickersCalendarHeader: {
    //   styleOverrides: {
    //     switchHeader: {
    //       marginLeft: 5,
    //       marginRight: 5,
    //       [exampleTheme.breakpoints.down('xs')]: {
    //         '& p': {
    //           fontSize: 15,
    //         },
    //       },
    //     },
    //     iconButton: {
    //       padding: 8,
    //     },
    //     dayLabel: {
    //       [exampleTheme.breakpoints.down('xs')]: {
    //         width: 30,
    //       },
    //     },
    //   }
    // },
    // MuiPickersDay: {

    //   styleOverrides: {
    //     day: {
    //       [exampleTheme.breakpoints.down('xs')]: {
    //         width: 30,
    //         height: 30,
    //         '& p': {
    //           fontSize: 14,
    //         },
    //       },
    //     },
    //   }
    // },
    MuiChip: {
      styleOverrides: {
        root: {
          color: textPrimaryColor,
        },
        colorSecondary: {
          color: textPrimaryColor,
        },
        deleteIconSmall: {
          width: 14,
          height: 14,
          fontSize: 14,
        },
      },
    },
    MuiAvatarGroup: {
      styleOverrides: {
        root: {
          paddingLeft: 8,
        },
        avatar: {
          marginLeft: -8,
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        colorDefault: {
          backgroundColor: primaryColor,
          color: whiteColor,
          fontSize: '1.2rem',
          '& svg': {
            width: '70%',
            height: '70%',
          },
        },
        rounded: {
          borderRadius: borderRadiusSize,
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        underlineAlways: {
          '&:hover': {
            textDecoration: 'none',
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          color: textGreyColor,
          borderBottom: `1px solid ${borderPrimaryColor}`,

          '& .MuiTabs-indicator': {
            transition: 'none !important',
          },
        },
        indicator: {
          height: 2,
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          fontWeight: 400,
          padding: '0 20px',
          [exampleTheme.breakpoints.up('sm')]: {
            minWidth: 0,
          },
          [exampleTheme.breakpoints.up('md')]: {
            fontSize: 18,
          },
          '&$selected': {
            fontWeight: 500,
          },
          '&:hover, &$selected, &$selected:hover': {
            color: textPrimaryColor,
          },
          transition: 'none !important',
          '& + &': {
            marginLeft: 10,
            [exampleTheme.breakpoints.up('md')]: {
              marginLeft: 20,
            },
          },
        },
        textColorInherit: {
          opacity: 1,
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        root: {
          padding: '8px 20px',
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        rounded: {
          borderRadius: 0,
        },
        root: {
          '& .MuiMenu-list': {
            paddingTop: 5,
            paddingBottom: 5,
            borderRadius: 0,
            '& .MuiSvgIcon-colorAction': {
              color: 'rgb(144, 144, 144)',
            },
            '& .MuiAutocomplete-root': {
              '& .MuiInputBase-root': {
                borderRadius: 0,
              },
            },
            '& .menu-dropdown-footer': {
              marginTop: 5,
              padding: '8px 0px 3px',
              textAlign: 'right',
              borderTop: '1px solid rgb(229, 229, 229)',
              '& button': {
                marginRight: 8,
              },
            },
            '& .MuiListSubheader-sticky': {
              lineHeight: 'normal',
              fontSize: '14px',
              fontWeight: '500',
              color: '#545E6B',
              padding: '8px 10px',
              margin: 0,
            },
            '& .MuiListItem-gutters': {
              paddingLeft: 20,
              paddingRight: 20,
            },
            '& .pagination-menu-item': {
              fontSize: '12px',
              color: ' #545E6B',
            },
          },
        },
      },
    },
    MuiGrid: {
      styleOverrides: {
        item: {
          paddingLeft: 16,
          paddingRight: 16,
        },
      },
    },
    MuiTable: {
      styleOverrides: {
        root: {
          backgroundColor: whiteColor,
          fontSize: 14,
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          display: 'flex',
          flex: '1 0 auto',
          borderBottom: `1px solid ${borderSecondaryColor}`,
          '&:last-child': {
            borderBottom: 'none',
          },
        },
        head: {
          backgroundColor: lighterGreyColor,
          '&, &:last-child': {
            borderBottom: `1px solid ${borderSecondaryColor}`,
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          fontSize: '12px',
          flex: '1 0 0',
          padding: '7px 14px',
          margin: 'auto 0',
          borderBottom: 'none',
          wordBreak: 'break-word',
          '& .custom-chip': {
            margin: 0,
          },
          [exampleTheme.breakpoints.up('md')]: {
            padding: '10px 20px',
          },
          [exampleTheme.breakpoints.up('xl')]: {
            padding: '15px 25px',
          },
          '&.text-right': {
            [exampleTheme.breakpoints.up('md')]: {
              textAlign: 'right',
            },
          },
        },
        head: {
          color: textGreyColor,
          position: 'relative',
          fontWeight: 400,
        },
        footer: {
          justifyContent: 'flex-end',
        },
      },
    },
    MuiTableFooter: {
      styleOverrides: {
        root: {},
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          height: 48,
          boxShadow: 'none',
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: { height: 48, minHeight: 48, maxHeight: '48px !important', paddingRight: '0 !important' },
        regular: {
          height: 48,
          minHeight: 48,
          [exampleTheme.breakpoints.down('xl')]: {
            height: 48,
            minHeight: 48,
          },
        },
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        toolbar: {
          color: textGreyColor,
          padding: 0,
          flexWrap: 'wrap',
          justifyContent: 'center',
          minHeight: 48,
          [exampleTheme.breakpoints.up('sm')]: {
            justifyContent: 'flex-end',
          },
          [exampleTheme.breakpoints.up('md')]: {
            paddingLeft: 20,
            paddingRight: 20,
          },
        },
        selectRoot: {
          border: 'none',
        },
        select: {
          paddingTop: 6,
          paddingBottom: 6,
          height: 26,
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          borderRadius: borderRadiusSize,
          backgroundColor: lighterGreyColor,
          maxWidth: 'fit-content',
          ...boxShadow,
          [exampleTheme.breakpoints.down('sm')]: {
            margin: 15,
          },
          [exampleTheme.breakpoints.down('xs')]: {
            '&$paperScrollBody': {
              maxWidth: 'calc(100% - 30px) !important',
            },
          },
          '& .MuiDialogTitle-root': {
            padding: '16px 24px',
            fontWeight: 500,
            fontSize: '1.25rem',
            lineHeight: 1.6,
            textAlign: 'center',
          },
          '& .MuiDialogContent-root': {
            padding: '0 24px 16px 24px',
            maxWidth: 980,
            fontSize: '16px',
            textAlign: 'center',
          },
          '& .MuiDialogContentText-root': {
            lineHeight: 1.2,
          },
          '& .MuiDialogActions-root': {
            padding: '16px 24px',
          },
        },
        paperWidthXs: {},
        paperWidthSm: {
          '&, &$paperScrollBody$paper': {
            [exampleTheme.breakpoints.up('sm')]: {
              maxWidth: 485,
            },
          },
        },
        paperWidthMd: {
          '&, &$paperScrollBody$paper': {
            [exampleTheme.breakpoints.up('sm')]: {
              maxWidth: 585,
            },
          },
        },
        paperWidthLg: {
          '&, &$paperScrollBody$paper': {
            [exampleTheme.breakpoints.up('md')]: {
              maxWidth: 800,
            },
          },
        },
        paperScrollBody: {},
        paperFullWidth: {
          width: 'calc(100% - 30px)',
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          borderRadius: 0,
          padding: '20px 20px 0',
          [exampleTheme.breakpoints.up('sm')]: {
            padding: '30px 40px 0',
          },
          [exampleTheme.breakpoints.up('md')]: {
            padding: '40px 60px 0',
          },
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          '-ms-overflow-style': 'none',
          scrollbarWidth: 'none',
          '&::-webkit-scrollbar': {
            display: 'none',
          },
          padding: 20,
          [exampleTheme.breakpoints.up('sm')]: {
            padding: '20px 40px',
          },
          [exampleTheme.breakpoints.up('md')]: {
            padding: '20px 60px',
          },
          '&:last-child': {
            paddingBottom: 20,
            [exampleTheme.breakpoints.up('sm')]: {
              paddingBottom: 30,
            },
            [exampleTheme.breakpoints.up('md')]: {
              paddingBottom: 40,
            },
          },
          '&:first-of-type': {
            paddingTop: 20,
            [exampleTheme.breakpoints.up('sm')]: {
              paddingTop: 30,
            },
            [exampleTheme.breakpoints.up('md')]: {
              paddingTop: 40,
            },
          },
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: '0 20px 20px',
          [exampleTheme.breakpoints.up('sm')]: {
            padding: '0 40px 30px',
          },
          [exampleTheme.breakpoints.up('md')]: {
            padding: '0 60px 40px',
          },
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          color: whiteColor,
          backgroundColor: headerColor,
          //border: `1px solid ${borderSecondaryColor}`,
          fontSize: '0.75rem', // 12px
          fontWeight: 400,
          lineHeight: 1.2,
          padding: '5px 10px',
          borderRadius: 5,
          ...boxShadow,
          [exampleTheme.breakpoints.up('sm')]: {
            fontSize: '0.875rem', // 14px
            padding: '10px 15px',
          },
        },
      },
    },
    MuiListItemSecondaryAction: {
      styleOverrides: {
        root: {
          right: 8,
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          backgroundColor: '#e5e5e5',
          display: 'block',
          margin: '5px 0',
        },
      },
    },
    MuiSlider: {
      styleOverrides: {
        root: {
          height: sliderHeight,
          padding: '10px 0',
        },
        rail: {
          height: sliderHeight,
        },
        track: {
          height: sliderHeight,
        },
        thumb: {
          marginTop: -4,
          backgroundColor: textPrimaryColor,
          '&::after': {
            top: -10,
            left: -10,
            right: -10,
            bottom: -10,
          },
          '&$focusVisible, &:hover': {
            boxShadow: '0 0 0 7px rgba(0, 0, 0, 0.07)',
          },
          '&$active, &$active:hover': {
            boxShadow: '0 0 0 10px rgba(0, 0, 0, 0.14)',
          },
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          verticalAlign: 'middle',
        },
      },
    },
  },
});

export default theme;
