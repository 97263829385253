import { Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import {
  borderPrimaryColor,
  dangerColor,
  greyColor,
  lightGreyColor,
  primaryColor,
  successColor,
  whiteColor,
} from 'configs/variables';

const styles = ({ breakpoints }: Theme) =>
  createStyles({
    arrow: {
      fontSize: 20,
      color: whiteColor,
    },
    profileButton: {
      padding: 0,
      '&:hover': {
        color: '#000',
        background: '#fff',
        '& svg,div': {
          color: '#000',
          stroke: '#000',
        },
      },
    },
    profileDropdown: {
      position: 'relative',
    },
    admin: {
      backgroundColor: successColor,
    },
    accountLink: {
      curosr: 'pointer',
    },
    hideButton: {
      backgroundColor: dangerColor,
    },
    showButton: {
      backgroundColor: successColor,
    },
    center: {
      display: 'inline-flex',
      verticalAlign: 'middle',
      textAlign: 'center',
    },
    link: {
      cursor: 'pointer',
    },
    avatarWrapper: {
      border: `1px solid ${whiteColor}`,
      textTransform: 'uppercase',
      background: 'transparent',
    },
    asyncAutocomplete: {
      '& .MuiFormControl-root': {
        overflow: 'hidden',
      },
      '& label': {
        fontSize: '12px',
        position: 'relative',
        margin: 0,
        padding: '5px 0',
      },
      '& input': {
        overflow: 'hidden',
      },
      '& .MuiInputAdornment-root': {
        '&::before': {
          content: '""',
          position: 'absolute',
          width: 55,
          height: 34,
          right: 0,
          backgroundColor: 'white',
        },
      },
    },
    textInput: {},
    tableHeadSort: {
      cursor: 'pointer',
      '& svg': {
        color: primaryColor,
      },
    },
    title: {
      fontSize: '24px',
      color: '#545e6b',
      fontWeight: 300,
    },
    noData: {
      display: 'block',
      textAlign: 'center',
      padding: 5,
      color: greyColor,
    },
    tableWrapper: {
      position: 'relative',
    },
    tableHeader: {
      margin: '0 0 15px',
      display: 'flex',
      alignItems: 'flex-end',
      justifyContent: 'space-between',
      [breakpoints.down('md')]: {
        flexDirection: 'column',
      },
    },
    tablePagination: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      [breakpoints.down('md')]: {
        marginTop: '-30px',
      },
    },
    tableMargin: {
      margin: '15px 0',
    },
    tdBar: {
      display: 'flex',
    },
    tableBarLabel: {
      margin: '0 15px',
      display: 'block',
      width: 75,
    },
    tableBarWrapper: {
      position: 'relative',
      flexGrow: 1,
      height: 8,
      border: '1px solid #e7ebee',
      margin: '0 15px 0 0',
    },
    tableBarTotalWrapper: {
      border: 'none',
      backgroundColor: 'transparent',
    },
    tableBarTotalChildWrapper: {
      border: 'none',
    },
    tableBar: {
      position: 'absolute',
      top: 0,
      width: 0,
      height: '100%',
    },
    tableBarTotal: {
      left: 0,
      right: 0,
      backgroundColor: '#1b87e6',
    },
    tableBarTotalChild: {
      left: 0,
      right: 0,
      border: 'none',
      backgroundColor: '#e9e9e9',
    },
    tableBarPositive: {
      left: 0,
      backgroundColor: '#61b361',
    },
    tableBarNegative: {
      left: 0,
      backgroundColor: 'red',
    },
    tableFilters: {
      flexGrow: 1,
      display: 'flex',
      justifyContent: 'center',

      '& .qp-form-group': {
        marginBottom: 0,
      },
      '& .qp-form-group:first-of-type': {
        paddingLeft: 0,
      },
      [breakpoints.down('md')]: {
        flexWrap: 'wrap',
        justifyContent: 'flex-start',
        '& .qp-form-group': {
          maxWidth: '50%',
        },
        '& .qp-form-group:first-of-type': {
          paddingLeft: 10,
        },
      },
    },
    tableSortActive: {
      color: '#1b87e6 !important',
    },

    tableRowClickable: {
      color: primaryColor,
      cursor: 'pointer',
    },
    modalTitle: {
      cursor: 'move',
      position: 'relative',
      display: 'flex',
      borderRadius: 0,
      justifyContent: 'center',
      height: 44,
      padding: '10px 15px',
      backgroundColor: lightGreyColor,
      '& .children': {
        textAlign: 'center',
        position: 'absolute',
        fontWeight: 600,
      },
    },
    modalActions: {
      justifyContent: 'space-between',
      borderTop: `1px solid ${borderPrimaryColor}`,
      backgroundColor: whiteColor,
      height: 50,
      padding: 10,
    },
    confirmationModalActions: {
      justifyContent: 'flex-end',
      borderTop: `1px solid ${borderPrimaryColor}`,
      backgroundColor: whiteColor,
      height: 50,
      padding: 10,
    },
    modalPreviewRow: {
      display: 'flex',
      alignItems: 'flex-start',
      flexDirection: 'column',
      margin: '15px 0',
      '& span': {
        marginRight: 30,
        fontSize: '12px',
        '&:first-of-type': {
          minWidth: 70,
        },
      },
    },
    modalPreviewCol: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-start',
      width: '100%',
      marginBottom: 10,
      borderBottom: `1px solid ${borderPrimaryColor}`,
      '&:last-child': {
        border: 'none',
      },
    },
    modalAdminToggle: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      width: '100%',
      maxWidth: '200px',
      marginTop: 10,
    },
    modalAdminLabel: {
      flex: '0 0 60%',
      width: '60%',
      fontSize: '12px',
    },
    modalAdminInput: {
      '& button': {
        '& span:first-of-type': {
          minWidth: 'auto',
          marginRight: 0,
        },
      },
    },
    upgradePlan: {
      background: 'orange',
      color: '#fff',
      fontSize: 15,
      borderRadius: 40,
    },
    dialogContentWrapper: {
      display: 'flex',
      '& h3': {
        color: '#1B3380',
      },
    },
    dialogContentDescription: {
      minWidth: 640,
      minHeight: 520,
      textAlign: 'left',
      padding: '32px 88px',
      '& a': {
        color: '#1B87E6',
        textDecoration: 'none',
      },
    },
    dialogContent: {
      padding: '0 !important',
    },
    dialogContentSecondSection: {
      minWidth: 300,
      minHeight: 520,
      textAlign: 'left',
      background: '#F5F5F5',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      padding: '32px 32px',
    },
    contactButton: {
      textAlign: 'center',
      textDecoration: 'none',
      backgroundColor: primaryColor,
      color: whiteColor,
      padding: '8px 10px',
      borderRadius: 2,
      border: 'none',
      cursor: 'pointer',
      '&:hover': {
        color: whiteColor,
        opacity: 0.7,
      },
    },
    dialogHeader: {
      backgroundColor: '#1B3380',
      color: whiteColor,
      '& span': {
        color: whiteColor,
        fontSize: 14,
        '& svg': {
          width: 12,
          height: 14,
        },
      },
    },
  });

export default styles;
