import { AxiosPromise } from 'axios';

import apiClient from 'configs/axios';
import { AnyObject } from 'types';
import { Codebook, CodeBookPost } from '../types/codebooks';
import { ReviewReplyTemplateParam } from '../types/reviewReply';

/**
 * Send request to synchronize data
 * @returns {AxiosPromise<any>}
 */
export function syncGoogleDataRequest(): AxiosPromise {
  return apiClient.get('/settings/sync-locations');
}

/**
 * Send request to remove all information about current user
 * @returns {AxiosPromise<any>}
 */
export function removeAllDataRequest(): AxiosPromise {
  return apiClient.get('/settings/remove-my-data');
}

/**
 * Send request to get last sync statistic
 * @returns {AxiosPromise<any>}
 */
export function lastSyncStatisticRequest(): AxiosPromise {
  return apiClient.get('/statistic/last-sync');
}

/*
 * Send request to get notification settings
 * @returns {AxiosPromise<any>}
 */
export function loadNotificationSettingsRequest(params: AnyObject): AxiosPromise {
  return apiClient.get('/settings/notifications', { params });
}

/**
 * Send request to change notification settings
 * @returns {AxiosPromise<any>}
 */
export function updateNotificationSettingsRequest({ id, ...data }: { id: number; data: AnyObject }): AxiosPromise {
  return apiClient.put(`/settings/notifications`, [{ id, ...data }]);
}

/**
 * Send request to change notification settings
 * @returns {AxiosPromise<any>}
 */
export function createNotificationSettingsRequest(data: { data: AnyObject }): AxiosPromise {
  return apiClient.put(`/settings/notifications`, [data]);
}

/*
 * Send request to get general settings
 * @returns {AxiosPromise<any>}
 */
export function loadGeneralSettingsRequest(params?: AnyObject): AxiosPromise {
  return apiClient.get('/settings', { params });
}

/**
 * Send request to change general settings
 * @returns {AxiosPromise<any>}
 */
export function updateOldGeneralSettingsRequest({ id, ...data }: { id: number; data: AnyObject }): AxiosPromise {
  return apiClient.patch(`/settings/${id}`, data);
}

/**
 * Send request to change general settings
 * @returns {AxiosPromise<any>}
 */
export function updateGeneralSettingsRequest(data: AnyObject): AxiosPromise {
  return apiClient.put(`/settings`, data);
}

/**
 * Send request to synchronize semantic
 * @returns {AxiosPromise<any>}
 */
export function syncSemanticDataRequest(data: { enabled: boolean }): AxiosPromise {
  return apiClient.post('/settings/sync-semantic', data);
}

/**
 * Send request to change connections settings
 * @returns {AxiosPromise<any>}
 */
export function connectionsToQPRequest(data: { code: string }): AxiosPromise {
  return apiClient.post(`/settings/connections/connect-to-question-pro`, data);
}

/**
 * Send request to connect to Trustpilot
 * @returns {AxiosPromise<any>}
 */
export function connectToTrustpilotRequest(data: { code: string }): AxiosPromise {
  return apiClient.post(`/settings/connections/connect-to-trustpilot`, data);
}
/**
 * Send request to connect to Google Play Store
 * @returns {AxiosPromise<any>}
 */
export function deleteConnectionSettings(id: number): AxiosPromise {
  return apiClient.delete(`/settings/connections/${id}`);
}

/*
 * Send request to get settings organization
 * @returns {AxiosPromise<any>}
 */
export function getSettingsOrganization(): AxiosPromise {
  return apiClient.get('/settings/organization');
}
export function getSyncStatus(): AxiosPromise {
  return apiClient.get('/settings/sync-manually-event');
}

/*
 * Send request to put settings organization
 * @returns {AxiosPromise<any>}
 */
export function putSettingsOrganization(params?: AnyObject): AxiosPromise {
  return apiClient.put('/settings/organization', params);
}

/*
 * Send request to post reviews import
 * @returns {AxiosPromise<any>}
 */
export function postReviewsImport(params: any): AxiosPromise {
  const formData = new FormData();
  formData.append('file', params);

  return apiClient.post('/reviews/import', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
}
export function deleteImportedReviews(): AxiosPromise {
  return apiClient.delete('/settings/integrations/delete-imported-reviews');
}

/*
 * Send request to get semantic logs chart data
 * @returns {AxiosPromise<any>}
 */
export function getSemanticLogsChart(params?: AnyObject): AxiosPromise {
  return apiClient.get('/semantic-logs/chart', { params });
}

/*
 * Send request to get semantic logs
 * @returns {AxiosPromise<any>}
 */
export function getSemanticLogs(params: AnyObject): AxiosPromise {
  return apiClient.get('/semantic-logs', { params });
}

/*
 * Send request to get credits
 * @returns {AxiosPromise<any>}
 */
export function getCredits(params: AnyObject): AxiosPromise {
  return apiClient.get('/credits', { params });
}

/*
 * Send request to post translation settings
 * @returns {AxiosPromise<any>}
 */
export function postOrganizationTranslationSettings(params: AnyObject): AxiosPromise {
  return apiClient.post('/organization-translation-settings', params);
}

/*
 * Send request to get translation settings
 * @returns {AxiosPromise<any>}
 */
export function getOrganizationTranslationSettings(params: AnyObject): AxiosPromise {
  return apiClient.get('/organization-translation-settings', { params });
}

/*
 * Send request to get translation logs
 * @returns {AxiosPromise<any>}
 */
export function getTranslatedLogs(params: AnyObject): AxiosPromise {
  return apiClient.get('/translation-logs', { params });
}

/*
 * Send request to patch translation settings
 * @returns {AxiosPromise<any>}
 */
export function patchOrganizationTranslationSettings(params: AnyObject): AxiosPromise {
  return apiClient.patch(`/organization-translation-settings`, params);
}

/**
 * Send request to post review reply template
 * @returns {AxiosPromise<any>}
 */
export function postReviewReplyTemplate(data: ReviewReplyTemplateParam): AxiosPromise {
  return apiClient.post('/review-reply-templates', data);
}

/**
 * Send request to get review reply template
 * @returns {AxiosPromise<any>}
 */
export function getReviewReplyTemplate(params = {}): AxiosPromise {
  return apiClient.get('/review-reply-templates', { params });
}

/**
 * Send request to patch review reply template by id
 * @returns {AxiosPromise<any>}
 */
export function patchReviewReplyTemplateById(id: number, data: ReviewReplyTemplateParam): AxiosPromise {
  return apiClient.patch(`/review-reply-templates/${id}`, data);
}

/**
 * Send request to delete review reply template by id
 * @returns {AxiosPromise<any>}
 */
export function deleteReviewReplyTemplateById(id: number): AxiosPromise {
  return apiClient.delete(`/review-reply-templates/${id}`);
}

/**
 * Send request to get codebooks
 * @returns {AxiosPromise<any>}
 */
export function getCodeBooks(): AxiosPromise {
  return apiClient.get('/codebooks');
}

/**
 * Send request to post codebooks
 * @returns {AxiosPromise<any>}
 */
export function postCodeBooks(codebook: CodeBookPost): AxiosPromise {
  return apiClient.post('/codebooks', codebook);
}

/**
 * Send request to patch codebooks
 * @returns {AxiosPromise<any>}
 */
export function patchCodeBookById(id: number, codebook: Codebook): AxiosPromise {
  return apiClient.patch(`/codebooks/${id}`, codebook);
}

/**
 * Send request to get codebook by id
 * @returns {AxiosPromise<any>}
 */
export function getCodeBookById(id: number): AxiosPromise {
  return apiClient.get(`/codebooks/${id}`);
}

/**
 * Send request to delete codebook by id
 * @returns {AxiosPromise<any>}
 */
export function deleteCodeBookById(id: number): AxiosPromise {
  return apiClient.delete(`/codebooks/${id}`);
}
