import CloseIcon from '@mui/icons-material/Close';
import LockIcon from '@mui/icons-material/Lock';
import { Dialog, DialogActions, DialogContent, DialogContentText, TextField } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { requiredMsg } from 'components/_shared/forms/messages';
import { error, success } from 'components/_shared/snackbars';
import EnsureTranslation from 'components/language/components/EnsureTranslation';
import { postMessageContactSales } from 'components/users/api';
import { useFormik } from 'formik';
import React, { ReactNode, useContext, useState } from 'react';
import { useIntl } from 'react-intl';
import * as Yup from 'yup';
import styles from '../../styles/index';
const useStyles = makeStyles(styles);

interface ModalContextProps {
  isOpen: boolean;
  openModal: () => void;
  closeModal: () => void;
}

interface ModalProviderProps {
  children: ReactNode;
}

export const contactSalesSchema = Yup.object().shape({
  firstName: Yup.string().required(requiredMsg),
  lastName: Yup.string().required(requiredMsg),
  email: Yup.string()
    .email()
    .required(requiredMsg),
  phoneNumber: Yup.string()
    .matches(/^\+?\d+$/, 'Invalid phone number')
    .max(15)
    .min(10),
  message: Yup.string(),
});
const ModalContext = React.createContext<ModalContextProps | undefined>(undefined);

const ModalProvider: React.FC<ModalProviderProps> = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);

  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);

  return <ModalContext.Provider value={{ isOpen, openModal, closeModal }}>{children}</ModalContext.Provider>;
};

const UpgradePlanModal: React.FC = () => {
  const classes = useStyles();
  const intl = useIntl();
  const modalContext = useContext(ModalContext);
  const [openModalForm, setOpenModalForm] = useState(false);

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      phoneNumber: '',
      message: '',
    },
    enableReinitialize: true,
    validationSchema: contactSalesSchema,
    onSubmit: values => {
      handleSave(values);
    },
  });
  const handleCancel = () => {
    setOpenModalForm(false);
    formik.resetForm();
  };
  const handleSave = async (values: any) => {
    await postMessageContactSales(values)
      .then((res: { data: any }) => {
        success(`Message has been sent`);
        if (res.data) {
          success(`Message has been sent`);
        }
        setOpenModalForm(false);
      })
      .catch(e => {
        error(e.message || 'Failed to save');
      });
  };
  if (!modalContext) return null;
  const { isOpen, closeModal } = modalContext;

  return (
    <>
      <Dialog open={isOpen} onClose={closeModal}>
        <div className={`${classes.dialogHeader} dialog-header`}>
          <span>
            <LockIcon />
            Upgrade to continue using CX Reputation
          </span>
          <CloseIcon onClick={closeModal} className="dialog-close-icon" />
        </div>
        <DialogContent className={classes.dialogContent}>
          <div className={classes.dialogContentWrapper}>
            <div className={classes.dialogContentDescription}>
              <h3>Location limit reached</h3>
              <p>
                Your account has reached location limit, to further sync the reviews from the additional locations,
                please upgrade.
                <br></br>
                <br></br>
                Contact <a href="mailto:sales@questionpro.com">sales@questionpro.com</a> to more details.
              </p>
            </div>
            <div className={classes.dialogContentSecondSection}>
              <div>
                <h3>Details</h3>
                <p>Please contact sales for more information on how to upgrade your account.</p>
              </div>
              <button className={classes.contactButton} onClick={() => setOpenModalForm(true)}>
                Contact Sales
              </button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
      <Dialog open={openModalForm} onClose={handleCancel}>
        <div className={`${classes.dialogHeader} dialog-header`}>
          <span>Contact Sales</span>
          <CloseIcon onClick={() => setOpenModalForm(false)} className="dialog-close-icon" />
        </div>
        <form onSubmit={formik.handleSubmit}>
          <DialogContent className={classes.dialogContent}>
            <DialogContentText padding={2}>
              <div className="qp-form-group has-input no-padding mbot-20 contact-sales-form">
                <div className="first">
                  <div className="first_inputs">
                    <label className="qp-control-label">First Name</label>
                    <TextField
                      fullWidth
                      hiddenLabel
                      id="filled-hidden-label-normal"
                      variant="filled"
                      value={formik.values.firstName}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      name="firstName"
                      placeholder="First Name"
                      error={formik.touched.firstName && Boolean(formik.errors.firstName)}
                      helperText={formik.touched.firstName && formik.errors.firstName}
                    />
                  </div>
                  <div className="first_inputs">
                    <label className="qp-control-label">Last Name</label>
                    <TextField
                      fullWidth
                      hiddenLabel
                      id="filled-hidden-label-normal"
                      variant="filled"
                      value={formik.values.lastName}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      name="lastName"
                      placeholder="Last Name"
                      error={formik.touched.lastName && Boolean(formik.errors.lastName)}
                      helperText={formik.touched.lastName && formik.errors.lastName}
                    />
                  </div>
                </div>
                <label className="qp-control-label">Email</label>
                <TextField
                  fullWidth
                  hiddenLabel
                  id="filled-hidden-label-normal"
                  variant="filled"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  name="email"
                  placeholder="Email"
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                />
                <label className="qp-control-label">Phone Number</label>
                <TextField
                  fullWidth
                  hiddenLabel
                  id="filled-hidden-label-normal"
                  variant="filled"
                  inputProps={{
                    inputMode: 'numeric',
                  }}
                  value={formik.values.phoneNumber}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  name="phoneNumber"
                  placeholder="Phone Number"
                  error={formik.touched.phoneNumber && Boolean(formik.errors.phoneNumber)}
                  helperText={formik.touched.phoneNumber && formik.errors.phoneNumber}
                />
                <label className="qp-control-label">Message</label>
                <TextField
                  fullWidth
                  hiddenLabel
                  id="filled-hidden-label-normal"
                  variant="filled"
                  // className="qp-form-input"
                  value={formik.values.message}
                  onChange={formik.handleChange}
                  name="message"
                  placeholder="Message"
                  multiline
                  rows={4}
                  error={formik.touched.message && Boolean(formik.errors.message)}
                  helperText={formik.touched.message && formik.errors.message}
                />
              </div>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <button type="button" className="qp-btn qp-secondary-btn " onClick={handleCancel}>
              <EnsureTranslation intl={intl} itemKey="general.buttonCancel" />
            </button>
            <button type="submit" className="qp-btn qp-primary-btn " autoFocus>
              <EnsureTranslation intl={intl} itemKey="general.sendMessage" />
            </button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};
export { ModalContext, ModalProvider, UpgradePlanModal };
