import { useEffect, useState } from 'react';

const STORAGE_KEY = 'APP_VERSION';
const BASE_PATH = '';

async function getAppVersion() {
  const newHeaders = new Headers();
  newHeaders.append('cache-control', 'no-cache');

  try {
    const res = await fetch(`${BASE_PATH}/app-version.txt`, {
      headers: newHeaders,
      credentials: 'omit',
      redirect: 'error',
    });
    return await res.text();
  } catch (err) {
    console.error('err', err);
    return;
  }
}

export function useIsLatestVersion(): boolean {
  const [isLatest, setIsLatest] = useState<boolean>(true);

  useEffect(() => {
    const readAppVersion = (): void => {
      getAppVersion().then(
        (response?: string) => {
          if (response?.startsWith('<')) {
            return;
          }

          if (response && response !== localStorage.getItem(STORAGE_KEY)) {
            setIsLatest(false);
            localStorage.setItem(STORAGE_KEY, response);
            console.log('A new version of app has been detected.');
            console.log('Version:', response);
            console.log('Updating...');
            emptyCache();
            return;
          }

          setIsLatest(true);
        },
        () => {
          setIsLatest(true);
        },
      );
    };

    readAppVersion();
  }, []);

  function emptyCache(): void {
    if ('caches' in window) {
      caches.keys().then(names => {
        names.forEach(name => {
          caches.delete(name);
        });
      });

      window.location.reload();
    }
  }

  return isLatest;
}
