export enum TYPE {
  'SET_TOKEN' = 1,
  'SET_USER',
  'CLEAR_STORAGE',
  'SET_FCM_TOKEN',
  'SET_LOGIN_AS',
}

export enum OAUTH_PROVIDERS {
  QUESTION_PRO = 'questionpro',
  TRUSTPILOT = 'trustpilot',
  GOOGLE_PLAY_STORE = 'googleplaystore',
  FACEBOOK = 'facebook',
}

export enum ROLES {
  SUPER_ADMIN = 1,
  MANAGER,
}

export enum PERMISSION_ACTIONS {
  'CREATE_ANY' = 1,
  'UPDATE_ANY',
  'READ_ANY',
  'DELETE_ANY',
}

const all = {
  [PERMISSION_ACTIONS.CREATE_ANY]: {},
  [PERMISSION_ACTIONS.READ_ANY]: {},
  [PERMISSION_ACTIONS.UPDATE_ANY]: {},
  [PERMISSION_ACTIONS.DELETE_ANY]: {},
};

interface PermissionsType {
  [key: number]: {
    [key: string]: any;
  };
}

export const PERMISSIONS_CONFIG: PermissionsType = {
  [ROLES.SUPER_ADMIN]: {
    dashboard: all,
    settings: all,
    adminPanel: all,
    users: all,
    user: all,
  },
  [ROLES.MANAGER]: {
    dashboard: {
      [PERMISSION_ACTIONS.READ_ANY]: {},
      [PERMISSION_ACTIONS.UPDATE_ANY]: {},
      [PERMISSION_ACTIONS.CREATE_ANY]: {},
    },
    settings: {
      [PERMISSION_ACTIONS.READ_ANY]: {},
      [PERMISSION_ACTIONS.UPDATE_ANY]: {},
      [PERMISSION_ACTIONS.CREATE_ANY]: {},
    },
  },
};
