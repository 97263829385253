import axios, { AxiosPromise } from 'axios';
import { error, success } from 'components/_shared/snackbars';

import apiClient from 'configs/axios';
import { AnyObject } from 'types';

/**
 * Send request to load connection data
 * @returns {AxiosPromise<any>}
 */
export function loadConnectionSettingsRequest(id: number | string): AxiosPromise {
  return apiClient.get(`/settings/integrations/${id}`);
}

/*
 * Send request to get connections settings
 * @returns {AxiosPromise<any>}
 */
export function loadConnectionsSettingsRequest(params?: AnyObject): AxiosPromise {
  return apiClient.get('/settings/integrations', { params });
}

/*
 * Send request to get reviews by source name
 * @returns {AxiosPromise<any>}
 */
export function getReviewsSourceName(): AxiosPromise {
  return apiClient.get('/reviews/source-name');
}

/**
 * Send request to change connections settings
 * @returns {AxiosPromise<any>}
 */
export function updateConnectionsSettingsRequest({ id, data }: { id: number; data: AnyObject }): AxiosPromise {
  return apiClient.patch(`/settings/integrations/${id}`, data);
}

/**
 * Send request to change connections settings
 * @returns {AxiosPromise<any>}
 */
export function createConnectionsSettingsRequest(data: { data: AnyObject }): AxiosPromise {
  return apiClient.post(`/settings/integrations`, data);
}

export function connectGooglePlayStore(code: string): AxiosPromise {
  return apiClient.get(`/settings/integrations/google-playstore/install?code=${code}`);
}

export function uninstallGooglePlayStore(): AxiosPromise {
  return apiClient.patch(`/settings/integrations/google-playstore/uninstall`);
}

export function setGooglePlayStorePackages(packages: string[]): AxiosPromise {
  return apiClient.post(`settings/integrations/google-playstore/packages`, { packages: packages });
}

export function getGooglePlayStorePackages(): AxiosPromise {
  return apiClient.get(`settings/integrations/google-playstore/packages`);
}

export function connectGoogleMyBusiness(code: string): AxiosPromise {
  return apiClient.post(`/settings/integrations/google-my-business/install?code=${code}`);
}

export function reconnectGoogleMyBusiness(code: string): AxiosPromise {
  return apiClient.post(`/settings/integrations/google-my-business/reconnect?code=${code}`);
}

export function uninstallGoogleMyBusiness(): AxiosPromise {
  return apiClient.patch(`/settings/integrations/google-my-business/uninstall`);
}

export function loginToReviewTrackers(auth: { username: string; password: string }): AxiosPromise {
  return axios.post(
    `https://api-gateway.reviewtrackers.com/auth`,
    {},
    {
      auth,
    },
  );
}

export function uninstallToReviewTrackers(): AxiosPromise {
  return apiClient.patch(`/settings/integrations/review-trackers/uninstall`);
}

export function connectToReviewTrackers(data: AnyObject): AxiosPromise {
  return apiClient.post(`/settings/integrations/review-trackers/install`, data);
}

export function uninstallQuestionPro(): AxiosPromise {
  return apiClient.patch(`/settings/integrations/questionpro/uninstall`);
}

export function connectToQuestionPro(): AxiosPromise {
  return apiClient.get(`/settings/integrations/questionpro/install`);
}

export function getQuestionProFeedbacks(): AxiosPromise {
  return apiClient.get(`/settings/integrations/questionpro/feedbacks`);
}

export function setQuestionProFeedbacks(id: number | string): AxiosPromise {
  return apiClient.put(`/settings/integrations/questionpro/feedbacks/${id}`);
}

export function uninstallTrustpilot(): AxiosPromise {
  return apiClient.patch(`/settings/integrations/trustpilot/uninstall`);
}

export function connectToTrustpilot(code: string): AxiosPromise {
  if (!code) {
    error('You should provide the code');
  }
  success('Trustpilot installed');
  return apiClient.post(`/settings/integrations/trustpilot/install?code=${code}`);
}
export function connectToFacebook(code: string): AxiosPromise {
  if (!code) {
    error('You should provide the code');
  }
  success('Facebook installed');
  return apiClient.get(`/settings/integrations/facebook/login?code=${code}`);
}

export function updateTrustpilotUrl(url: string): AxiosPromise {
  return apiClient.patch(`/settings/integrations/trustpilot/business-url`, { url });
}

export function getTrustpilotUrl(): AxiosPromise {
  return apiClient.get(`/settings/integrations/trustpilot/business-url`);
}
export function getIntegrationStatus(id: any): AxiosPromise {
  return apiClient.get(`/settings/integrations/integration-event?integrationId=${id}`);
}
export function uninstallFacebook(): AxiosPromise {
  return apiClient.patch(`/settings/integrations/facebook/uninstall`);
}

export function getZapierInviteLink(): AxiosPromise {
  return apiClient.get(`/zapier/get-invite-link`);
}
