import { useEffect, useCallback } from 'react';

export interface UseGoogleLogin {
  handleSubmit: () => Promise<{ code: string }>;
}

export default function useGoogleAuth(): UseGoogleLogin {
  useEffect(() => {
    window.gapi.load('auth2', () => {
      window.gapi.auth2.init({
        client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
        scope: 'https://www.googleapis.com/auth/business.manage https://www.googleapis.com/auth/androidpublisher',
      });
    });
  }, []);

  const handleSubmit = useCallback(async () => {
    const GoogleAuth = window.gapi.auth2.getAuthInstance();

    return GoogleAuth.grantOfflineAccess({
      scope: 'https://www.googleapis.com/auth/business.manage https://www.googleapis.com/auth/androidpublisher',
      prompt: 'consent',
    });
  }, []);

  return { handleSubmit };
}
