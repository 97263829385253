import { Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';

const style = ({ breakpoints }: Theme) =>
  createStyles({
    alertRoot: {
      height: 48,
      padding: '2px 12px',
      border: 'none',
      display: 'flex',
      alignItems: 'center',
      borderRadius: 3,
      '& .MuiAlert-message': {
        paddingLeft: 15,
        borderLeft: '1px solid #348312',
      },
    },

    // Alert Color
    error: {
      backgroundColor: '#FFBABA',
      color: '#D8000C',
      '& $alertIcon': {
        color: '#D8000C',
      },
      '& svg': {
        fill: '#D8000C',
      },
    },
    success: {
      backgroundColor: '#DFF2BF',
      color: '#270',
      '& $alertIcon': {
        color: '#270',
      },
      '& svg': {
        fill: '#270',
      },
    },
    warning: {
      backgroundColor: '#FEEFB3',
      color: '#9F6000',
      '& $alertIcon': {
        color: '#9F6000',
      },
      '& svg': {
        fill: '#9F6000',
      },
    },
    info: {
      backgroundColor: '#BEF',
      color: '#059',
      '& $alertIcon': {
        color: '#059',
      },
      '& svg': {
        fill: '#059',
      },
    },
    alertIcon: {
      // Define styles for alertIcon here
    },
  });

export default style;
