import React, { Children, useReducer } from 'react';

import { DispatchContext, StateContext } from '../context';
import { State, Action } from '../types/context';
import { TYPE } from '../constants';

interface Props {
  readonly children?: React.ReactNode;
}

function reducer(state: State, action: Action) {
  switch (action.type) {
    case TYPE.SET_TOKEN: {
      return { ...state, token: action.payload };
    }
    case TYPE.SET_USER: {
      return { ...state, user: action.payload };
    }
    case TYPE.SET_FCM_TOKEN: {
      return { ...state, FCMToken: action.payload };
    }
    case TYPE.SET_LOGIN_AS: {
      return { ...state, loginAs: action.payload };
    }
    case TYPE.CLEAR_STORAGE:
      return {};
    default: {
      return state;
    }
  }
}

function AuthProvider({ children }: Props) {
  const [state, dispatch] = useReducer(reducer, {});
  const token = localStorage.getItem('token');
  const loginAs = localStorage.getItem('loginAs');
  const FCMToken = localStorage.getItem('FCMToken');

  if (!state.token && token) {
    dispatch({ type: TYPE.SET_TOKEN, payload: token });
  }

  if (loginAs && !state.loginAs) {
    dispatch({ type: TYPE.SET_LOGIN_AS, payload: JSON.parse(loginAs) });
  }

  if (FCMToken && !state.FCMToken) {
    dispatch({ type: TYPE.SET_FCM_TOKEN, payload: FCMToken });
  }

  return (
    <StateContext.Provider value={state}>
      <DispatchContext.Provider value={dispatch}>{Children.only(children)}</DispatchContext.Provider>
    </StateContext.Provider>
  );
}

export default AuthProvider;
