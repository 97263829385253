import React, { ComponentType } from 'react';

import { Loader } from 'components/_shared/loader';
import { useAuth } from '..';

const auth = (WrappedComponent: ComponentType) =>
  function Auth(props: object) {
    const { token, user } = useAuth();

    return (
      <Loader loading={Boolean(token && !user)}>
        <WrappedComponent {...props} />
      </Loader>
    );
  };

export default auth;
