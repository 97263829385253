import { Theme } from '@mui/material';

import createStyles from '@mui/styles/createStyles';

const styles = (theme: Theme) =>
  createStyles({
    loaderWrapper: {
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      zIndex: 1000,
      borderRadius: 'inherit',
      '&$loaderFullPageWrapper': {
        position: 'fixed',
      },
      backgroundColor: '#ffff',
      backdropFilter: 'blur(5px)',
      border: '0 solid',
      '-webkit-filter': 'blur(5px)',
      '&$loaderWrapperBackground': {
        filter: 'blur(0)',
        '-webkit-filter': 'blur(0)',
      },
    },
    loaderWrapperBackground: {},
    loader: {
      color: theme.palette.primary.main,
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: '-25px',
      marginLeft: '-25px',
      zIndex: 1000,
      filter: 'blur(0)',
      '-webkit-filter': 'blur(0)',
    },
    loaderFullPageWrapper: {},
  });

export default styles;
