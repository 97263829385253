import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { StyledEngineProvider, Theme, ThemeProvider } from '@mui/material/styles';
import React from 'react';
import { createRoot } from 'react-dom/client';

// global styles
import 'styles/scss/style.scss';

import { SnackbarsProvider } from 'components/_shared/snackbars';
import { AuthProvider } from 'components/auth';
import { LanguageProvider } from 'components/language';
import { LanguageContextProvider } from 'components/language/context/LanguageContext';
import { ModalProvider } from 'components/users/components/UpgradePlan/upgradePlan';
import { translationMessages } from 'configs/formatTranslationMessages';
import theme from 'configs/theme';
import App from 'containers/App';
import { QueryClient, QueryClientProvider } from 'react-query';

const queryClient = new QueryClient();

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

const MOUNT_NODE = document.getElementById('root') as HTMLElement;

const root = createRoot(MOUNT_NODE);

const render = (messages: any, Component = App) => {
  root.render(
    <LanguageContextProvider>
      <LanguageProvider messages={messages}>
        <QueryClientProvider client={queryClient}>
          <StyledEngineProvider injectFirst>
            <ModalProvider>
              <ThemeProvider theme={theme}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <AuthProvider>
                    <SnackbarsProvider>
                      <Component />
                    </SnackbarsProvider>
                  </AuthProvider>
                </LocalizationProvider>
              </ThemeProvider>
            </ModalProvider>
          </StyledEngineProvider>
        </QueryClientProvider>
      </LanguageProvider>
    </LanguageContextProvider>,
  );
};

if (!(window as any).Intl) {
  new Promise(resolve => {
    // eslint-disable-next-line global-require
    resolve(import('intl'));
  })
    .then(() => render(translationMessages))
    .catch(err => {
      throw err;
    });
} else {
  render(translationMessages);
}
