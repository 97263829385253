import React, { memo } from 'react';
import { useHistory } from 'react-router-dom';

import { useQuery } from 'components/_shared/hooks';
import { Loader } from 'components/_shared/loader';
import { connectionsToQPRequest } from 'components/settings/api';

import { connectToFacebook, connectToTrustpilot } from 'components/connections/api';
import { OAUTH_PROVIDERS, questionProRequest, useAuth } from '..';

interface Props {
  provider: string;
  code: string | null;
  state: string | null;
  facebookAccessToken: string | null;
}

function AuthCallback({ provider, code, state, facebookAccessToken }: Props) {
  const history = useHistory();
  const { setToken } = useAuth();
  //@ts-ignore
  const { data, isFetching } = useQuery(provider, () => {
    switch (provider) {
      case OAUTH_PROVIDERS.QUESTION_PRO: {
        if (code) {
          if (!state || state === 'login') {
            return questionProRequest(code);
          }

          return connectionsToQPRequest({ code });
        }
        break;
      }
      case OAUTH_PROVIDERS.TRUSTPILOT: {
        if (code) return connectToTrustpilot(code);
        break;
      }
      case OAUTH_PROVIDERS.FACEBOOK: {
        if (facebookAccessToken) return connectToFacebook(facebookAccessToken);
        break;
      }
      default: {
        return Promise.reject();
      }
    }
  });

  if (data) {
    if (provider === OAUTH_PROVIDERS.TRUSTPILOT) {
      history.push('/settings/connections');
    } else if (provider === OAUTH_PROVIDERS.FACEBOOK) {
      history.push('/settings/connections');
    } else {
      if (!state || state === 'login') {
        setToken(data.data.accessToken);
        history.push('/');
      } else {
        history.push('/settings/connections');
      }
    }
  }

  return (
    <div>
      <Loader loading={isFetching} />
    </div>
  );
}

export default memo(AuthCallback);
